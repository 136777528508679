import React from 'react'
import Seo from '../components/molecules/Seo'

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found"/>
    <div>
      <h1>NOT FOUND</h1>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </div>
  </>
);

export default NotFoundPage
